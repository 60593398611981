import {
  ADDFROMREDIS,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_ALL_FROM_CART,
  DECREMENT,
  INCREMENT,
  SET_LOADING_INITIAL,
  ADD_TO_FILTER,
  CLEAR_ALL_FROM_FILTER,
  TIPO_CAMBIO,
  REMOVE_ALL,
  ADDPRICE
} from "./types";
import Carrito from "../actions/carritoActions";
import React, { useEffect, useState,useContext } from "react";
import { getTipoCambio } from './../actions/carritoActions';
import {getMoneda} from "../components/Result/Mask/catalogorequest";
import Context from "./Context";
import { getProductDetail,getProductDetailName, postComentario, getComentarios ,getProductPrice,getRespuesta,getPreguntas,getPromo,getProductVariant ,postPregunta,getVariant,getWishList,searchgetWishList,getWishExist,editWishList,getElement} from "../actions/resultActions";
import UserService from "../../src/components/UserService ";


// const setFilter = (state, filter) => {
//   debugger
//   return { ...state, filterv2: filter };
// };
const Details = () => {
  const [moneda, setMoneda] = useState([]);
  const getMonedas = async () => {
    const moned = await getMoneda();
    setMoneda(moned.data);
   
    window.localStorage.setItem("monedasup", moned.data.abreviatura);

    
  };
  useEffect(() => {
  
    //  getPromos();
    getMonedas();

    },[]);
    return moneda
}

const addProductToFilter = (state, filter) => {
  const copy = [...state.filters];

  const curItemIndex = copy.findIndex((i) => i._id === filter._id);

  if (curItemIndex < 0) {
    copy.push(filter);
  }
  
  return { ...state, filters: copy };
};

const addProductToCart = (state, product) => {
  const inve= product.maxQuantity;
 
  const copy = [...state.carts];
  const curItemIndex = copy.findIndex((i) => i.product._id === product._id);
  window.localStorage.setItem("monedaCambio",product.moned);



  if (curItemIndex < 0) {
    copy.push({ product, quantity: 1 });
  } else {
    const copyItem = { ...copy[curItemIndex] };
    copyItem.quantity++;
   
    copyItem.product.infoAdicional = product.infoAdicional;
    copy[curItemIndex] = copyItem;
  }
  var valor;
  let _subTotal;
  if(product.moned === "USD")
  {
    valor = copy.reduce(
      (totalCalories, i) => totalCalories + (i.product.price ).toFixed(2) * i.quantity,
      0
    );
    _subTotal = (valor / 1.16).toFixed(2)
  }
  else{
    valor = copy.reduce(
      (totalCalories, i) => totalCalories + (i.product.price).toFixed(2) * i.quantity,
      0
    );
    _subTotal = (valor / 1.16).toFixed(2);
  }
  
  
  const subtotal = [];
  subtotal.push(_subTotal);

  const iva = [];
  iva.push((valor - _subTotal).toFixed(2));

  const total = [];
  total.push(valor);
 
  Carrito.setCarrito(copy);
  
  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total };
};
const wish = async(id)=>{
  const usuario = UserService.getUserData();
  const MyObject = 
  {idProducto: id,
   idusuario: usuario.id,
   activo: true
   }
 const JsonArray2 = JSON.stringify( MyObject);
 const result = await getWishList(JsonArray2);
} 
const removeProductFromCart = (state, productID) => {
  const copy = [...state.carts];
  const curItemIndex = copy.findIndex((i) => i.product._id === productID);
  let moneda = window.localStorage.getItem("removemoneda");
  const curItem = { ...copy[curItemIndex] };
  curItem.quantity = 0;
 
  if (curItem.quantity <= 0) {
    copy.splice(curItemIndex, 1);
  } else {
    copy[curItemIndex] = curItem;
  }
let val;
if(moneda==="USD")
{
  val = copy.reduce(
    (totalCalories, i) => totalCalories + (i.product.price * i.product.tipoCambio).toFixed(2) * i.quantity,
    0
  );
}else
{
   val = copy.reduce(
    (totalCalories, i) => totalCalories + (i.product.price).toFixed(2) * i.quantity,
    0
  );
}

  let _subTotal = (val / 1.16).toFixed(2)
  const subtotal = [];
  subtotal.push(_subTotal);

  const iva = [];
  iva.push((val - _subTotal).toFixed(2));

  const total = [];
  total.push(val);

  Carrito.setCarrito(copy);
  
  if(state.carts[curItemIndex].product.wishList ===true&&state.carts[curItemIndex].product.pedidos===true){
    wish(productID);
    
  }
  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total };
};

const removeAllProductsFromCart = (state) => {
  return {
    ...state,
    carts: [],
    subtotal: [],
    iva: [],
    total: []
  };
};
const decrementCounter = (state, productID) => {
  let TC = state.getTipoCambio;
  const copy = [...state.carts];
  const curItemIndex = copy.findIndex((i) => i.product._id === productID);
  const copyItem = { ...copy[curItemIndex] };
  if (copyItem.quantity == 1) {
    return { ...state, carts: copy };
  }
  copyItem.quantity--;
  copy[curItemIndex] = copyItem;

  const val = copy.reduce(
    (totalCalories, i) => totalCalories + (i.product.price).toFixed(2) * i.quantity,
    0
  );

  let _subTotal = (val / 1.16).toFixed(2)
  const subtotal = [];
  subtotal.push(_subTotal);

  const iva = [];
  iva.push((val - _subTotal).toFixed(2));

  const total = [];
  total.push(val);

  Carrito.setCarrito(copy);
  
  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total };
};

const getMonedas = async () => {
  const moned = await getMoneda();
 
 return moned.abreviatura;
};

const incrementCounter = (state, productID) => {
  

  let TC = state.getTipoCambio;
  const copy = [...state.carts];
  const curItemIndex = copy.findIndex((i) => i.product._id === productID);
  const copyItem = { ...copy[curItemIndex] };
  const abreviatura=window.localStorage.getItem("monedas");
  

  copyItem.quantity++;
  copy[curItemIndex] = copyItem;
  const val = copy.reduce(
    (totalCalories, i) => totalCalories + (i.product.price ).toFixed(2) * i.quantity,
    0
  );

  let _subTotal = (val / 1.16).toFixed(2)
  const subtotal = [];
  subtotal.push(_subTotal);

  const iva = [];
  iva.push((val - _subTotal).toFixed(2));

  const total = [];
  total.push(val);

  Carrito.setCarrito(copy);
  
  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total };
};

const clearFilter = (state) => {
  return {
    ...state,
    filters: [],
  };
};

const clearCart = (state) => {
  //Carrito.setCarrito([]);
  window.localStorage.removeItem("carritoID");
  return {
    ...state,
    carts: [],
  };
};

const setLoadingInitial = (state) => {
  return {
    ...state,
    loadingInitial: false,
  };
};

const addTipoCambio = (state, tipoCambio) => {
  return { ...state, getTipoCambio: tipoCambio };
};
const addPrice = (state, product) => {
  let cambio = window.localStorage.getItem("cambio");
  let num= Number(cambio);
  const copy = [...state.carts];
  const curItemIndex = copy.findIndex((i) => i.product._id === product._id);
  if (curItemIndex < 0) {
    copy.push({ product, quantity: product.quantity });
  } else {
    copy[curItemIndex] = { product, quantity: product.quantity };
  }

 
  var valor;
  let _subTotal;
  if(product.moned === "USD")
  {
    valor = copy.reduce(
      (totalCalories, i) => totalCalories + (i.product.price*num ).toFixed(2) * i.quantity,
      0
    );
    _subTotal = (valor / 1.16).toFixed(2)
  }
  else{
    valor = copy.reduce(
      (totalCalories, i) => totalCalories + (i.product.price/num).toFixed(2) * i.quantity,
      0
    );
    _subTotal = (valor / 1.16).toFixed(2);
  }
  
  
  const subtotal = [];
  subtotal.push(_subTotal);

  const iva = [];
  iva.push((valor - _subTotal).toFixed(2));

  const total = [];
  total.push(valor);
  
  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total };
};

const addRedisData = (state, product) => {
  let monedaC = window.localStorage.getItem("monedaCambio");
  let mone= window.localStorage.getItem("monedasup"); 
  if(monedaC !== mone)
  {
if(monedaC==="MXN")
{
  product.price=product.price/product.tipoCambio
}else
{
  product.price=product.price*product.tipoCambio

}
  }
  const copy = [...state.carts];
  const curItemIndex = copy.findIndex((i) => i.product._id === product._id);
  if (curItemIndex < 0) {
    copy.push({ product, quantity: product.quantity });
  } else {
    copy[curItemIndex] = { product, quantity: product.quantity };
  }

 
  var valor;
  let _subTotal;
  valor = copy.reduce(
    (totalCalories, i) => totalCalories + (i.product.price ).toFixed(2) * i.quantity,
    0
  );
  _subTotal = (valor / 1.16).toFixed(2)
  
  const subtotal = [];
  subtotal.push(_subTotal);

  const iva = [];
  iva.push((valor - _subTotal).toFixed(2));

  const total = [];
  total.push(valor);
  
  return { ...state, carts: copy, subtotal: subtotal, iva: iva, total: total };
};

const calIva = (subtotal, state) => {
  return subtotal / 1.16; // * state.getTipoCambio;
};

const calTotal = (subtotal, state) => {
  return subtotal; // * state.getTipoCambio; // + calIva(subtotal, state);
};

export default (state, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return addProductToCart(state, action.payload);
    case REMOVE_FROM_CART:
      return removeProductFromCart(state, action.payload);
    case CLEAR_ALL_FROM_CART:
      return clearCart(state);
    case DECREMENT:
      return decrementCounter(state, action.payload);
    case INCREMENT:
      return incrementCounter(state, action.payload);
    case ADDFROMREDIS:
      return addRedisData(state, action.payload);
    case SET_LOADING_INITIAL:
      return setLoadingInitial(state);
    case ADD_TO_FILTER:
      return addProductToFilter(state, action.payload);
    case CLEAR_ALL_FROM_FILTER:
      return clearFilter(state);
    case TIPO_CAMBIO:
      return addTipoCambio(state, action.payload);
    case REMOVE_ALL:
      return removeAllProductsFromCart(state);
      case ADDPRICE:
        return addPrice(state, action.payload);
    default:
      return state;
  }
};
