import {urls} from './Url';
const dominio=window.localStorage.getItem("dominios");
const baseFiles = {
  imgProduct: urls.direccion+"/IntegrinoxFiles/",
  facturacion:  urls.direccion+"/IntegrinoxFiles/"
       
};

const baseUrls = {
  baseUrl:
  urls.direccion,
  identity:
 urls.direccion+"/services/Identidad/api/",
  catalogo: urls.direccion+"/services/Catalogo/api/",
  notificacion:
  urls.direccion+"/services/Notificacion/api/",
  almacen:urls.direccion+"/services/Almacen/api/",
  //"https://localhost:44382/api/"",
  almacen2: urls.direccion+"/services/Almacen/api/",
  shop:
  urls.direccion+ "/services/Almacen/api/",
          
  logistica:
  urls.direccion+"/services/Logistica/api/",
  carrito:
  urls.direccion+ "/services/Carrito/api/",
  promociones:
  urls.direccion+"/services/Promociones/api/",
  pedido:"https://api.clikalo.com/services/Orden/api/",
  skydropx:"https://api.clikalo.com/services/Orden/api/",
  
  carritodev: urls.direccion,
};
const Base = {
  baseUrls,
  baseFiles,
};

export default Base;