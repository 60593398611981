// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
import GlobalState from "../src/store/GlobalState";
import React ,{Suspense} from "react";
import ReactDOM from "react-dom";
 //import App from "./App";
import { createBrowserHistory } from "history";
import "semantic-ui-css/components/loader.min.css";
import "semantic-ui-css/components/segment.min.css";
import "semantic-ui-css/components/icon.min.css";
import "antd/lib/image/style/index.css";
import { Router } from 'react-router-dom';

import{COLORS} from './Views/Colores';
import {getKey,getKeyNot,getKeyPay,getColor,getLogo} from "./actions/resultActions";
// import "antd/dist/antd.css";
const App = React.lazy(() => import("./App"));
const customHistory = createBrowserHistory();



console.log("Entorno corriendo>> " + process.env.REACT_APP_BASE_URL);

console.log("Entorno petifu>>"+process.env.NODE_ENV);

ReactDOM.render( 
  // <React.StrictMode>
 
   <GlobalState>
         <Suspense fallback={<div></div>}>
              <App />
              </Suspense>
  </GlobalState>  ,
   
  
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
