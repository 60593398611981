import Keycloak from "keycloak-js";
import { useContext } from "react";
import Context from "../store/Context";
import { useHistory } from 'react-router-dom'

const _kc = new Keycloak(
  process.env.NODE_ENV === 'production' ? "/keycloakPublic.json" :
    process.env.REACT_APP_BASE_URL == "prd" ? '/keycloakPublic.json'
      : "/keycloak.json"
);
// const _kc = new Keycloak('/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
      flow: "standard", //'standard'|'implicit'|'hybrid';
      loginInframe: { enable: false },
      enableLogging: true,
    })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      }
    });
};

console.log(_kc);
const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const setToken = (token) => (_kc.token = token);
const getAll = () => _kc;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(300).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getUserData = () => {
  return {
    NombreCompleto: _kc.tokenParsed?.name,
    Nombre: _kc.tokenParsed?.given_name,
    Apellido: _kc.tokenParsed?.family_name,
    email: _kc.tokenParsed?.email,
    userName: _kc.tokenParsed?.preferred_username,
    token: _kc.token,
    refresh_token: _kc.refreshToken,
    roles: _kc.realmAccess,
    roles2: _kc.resourceAccess,
    id: _kc.tokenParsed?.sub,
  };
};

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

export const getKCConfig = () => _kc;

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  token: _kc.token,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getUserData,
  setToken,
  getAll,
};

export default UserService;
