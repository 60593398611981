import React, { useReducer } from "react";
import Context from "./Context";
import Reducer from "./Reducer";
import {
  ADDFROMREDIS,
  ADD_TO_CART, ADD_TO_FILTER, CLEAR_ALL_FROM_CART, CLEAR_ALL_FROM_FILTER, DECREMENT,
  INCREMENT, REMOVE_FROM_CART, SET_LOADING_INITIAL, TIPO_CAMBIO, REMOVE_ALL,ADDPRICE
} from "./types";


export default function GlobalState(props) {
  const [state, dispatch] = useReducer(Reducer, {
    carts: [], filters: [], filterv2: {
      categoriaId: '1',
      precio: [0, 22000],
      pathCategory: ['18', '19']
    }
  });

  // // # add product to cart
  // const setFilter = (filter) => {
  //   dispatch({
  //     type: SET_FILTER,
  //     payload: filter,
  //   });
  // };

  // # add product to cart
  const addProductToCart = (product) => {
    dispatch({
      type: ADD_TO_CART,
      payload: product,
    });
  };

  const addProductFilter = (filter) => {
    dispatch({
      type: ADD_TO_FILTER,
      payload: filter,
    });
  };

  const addCartFromRedis = (product) => {
    dispatch({
      type: ADDFROMREDIS,
      payload: product,
    });
  };
  const addPrice = (product) => {
    dispatch({
      type: ADDPRICE,
      payload: product,
    });
  };

  // # remove product from cart
  const removeProductFromCart = (productID) => {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: productID,
    });
  };
  const removeAllProductsFromCart = () => {
    dispatch({
      type: REMOVE_ALL
    });
  };
  //decrement
  const decrementCounter = (productID) => {
    dispatch({
      type: DECREMENT,
      payload: productID,
    });
  };

  const incrementCounter = (productID) => {
    dispatch({
      type: INCREMENT,
      payload: productID,
    });
  };

  // # clear all product from cart
  const clearProductFilter = () => {
    dispatch({
      type: CLEAR_ALL_FROM_FILTER,
    });
  };

  const addTipoCambio = (tipoCambio) => {
    dispatch({
      type: TIPO_CAMBIO,
      payload: tipoCambio,
    });
  };

  const clearCart = () => {
    dispatch({
      type: CLEAR_ALL_FROM_CART,
    });
  };

  const setLoaded = () => {
    dispatch({
      type: SET_LOADING_INITIAL,
    });
  };

  return (
    <Context.Provider
      value={{
        addProductToCart: addProductToCart,
        removeProductFromCart: removeProductFromCart,
        clearCart: clearCart,
        setLoaded: setLoaded,
        loadingInitial: state.loadingInitial,
        carts: state.carts,
        subtotal: state.subtotal,
        decrementCounter: decrementCounter,
        incrementCounter: incrementCounter,
        addCartFromRedis: addCartFromRedis,
        iva: state.iva,
        total: state.total,
        filters: state.filters,
        addProductFilter: addProductFilter,
        clearProductFilter: clearProductFilter,
        addTipoCambio: addTipoCambio,
     ///   getTipoCambio: state.getTipoCambio,
     getTipoCambio: 1,
 
     filterv2: state.filterv2,
        removeAllProductsFromCart: removeAllProductsFromCart,
        addPrice :addPrice ,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}
