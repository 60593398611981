export const ADD_TO_CART = "ADD_TO_CART";
export const SET_FILTER = "SET_FILTER";
export const ADD_TO_FILTER = "ADD_TO_FILTER";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_ALL_FROM_CART = "CLEAR_ALL_FROM_CART";
export const CLEAR_ALL_FROM_FILTER = "CLEAR_ALL_FROM_FILTER";
export const DECREMENT = "DECREMENT";
export const TIPO_CAMBIO = "TIPO_CAMBIO";
export const INCREMENT = "INCREMENT";
export const ADDFROMREDIS = "FROMREDIS";
export const SET_LOADING_INITIAL = "SET_LOADING_INITIAL"
export const REMOVE_ALL = "REMOVE_ALL"
export const ADDPRICE="ADDPRICE";

